import clickOutside from '../directives/click-ouside.js'
import ukFlex from '../directives/uk-flex'

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

export default {
  install(Vue) {
    Vue.directive('click-outside', clickOutside)
    Vue.directive('flex', ukFlex)
  }
}
