import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DB_URL
}

firebase.initializeApp(firebaseConfig)

const db = firebase.database()
const auth = firebase.auth()
var fbProfider = new firebase.auth.FacebookAuthProvider()
var googleProfider = new firebase.auth.GoogleAuthProvider()

fbProfider.setCustomParameters({
  display: 'popup'
})

export { db, auth, fbProfider, googleProfider }
