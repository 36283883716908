const types = ['top', 'middle', 'stretch', 'bottom']

export default {
  bind: function(el, binding) {
    const modifiers = binding.modifiers
    const classList = el.classList

    if (!classList.contains('uk-flex')) {
      el.classList.add('uk-flex')
    }

    if (!classList.contains('uk-flex-')) {
      for (const i in types) {
        const type = types[i]
        if (modifiers[type]) {
          el.classList.add('uk-flex-' + type)
          break
        }
      }
    }
  }
}
