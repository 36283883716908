<template>
  <div class="uk-container">
    <div
      @click="go({ name: 'action-products', params: { actionId: action.id } })"
      class="uk-card-body uk-position-relative uk-padding-small uk-padding-remove-left action"
      v-for="action in actions"
      :key="action.id"
    >
      <font-awesome-icon
        icon="chevron-right"
        class="uk-position-center-right uk-text-light"
        size="2x"
      />
      <h4 class="uk-text-emphasis uk-margin-remove">
        {{ retailerName(action.retailerId) }}
      </h4>
      <div class="date uk-text-muted">
        {{ formatDate(action.date) }}
      </div>
      <div class="coop uk-text-muted">
        {{ coopName }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      result: []
    }
  },
  computed: {
    ...mapGetters({
      actions: 'getActions',
      coopName: 'coopShortName',
      retailerName: 'retailerNameById'
    })
  },
  methods: {
    formatDate: date => new Date(date).toLocaleString(),
    go(route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss" scope>
@import './assets/theme.scss';
.action {
  border-bottom: 1px solid $global-border;
}
</style>
