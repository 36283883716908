<template>
  <div class="uk-container">
    <div
      class="uk-card uk-card-body uk-position-relative uk-padding-small"
      v-for="product in products"
      :key="product.id"
      @click="addToCart(product)"
    >
      <div class="uk-position-center-right uk-text-light add">
        <font-awesome-icon class="uk-position-center" icon="plus" />
      </div>
      <h4
        :class="['uk-margin-remove', product.cartItem ? 'uk-text-primary' : '']"
      >
        {{ product.name }}
      </h4>
      {{ product.price.toFixed(2) }} {{ product.unit }}
      <span v-if="product.cartItem"
        >( <font-awesome-icon icon="cart-arrow-down" />
        {{ product.cartItem.quantity }} )</span
      >
    </div>
  </div>
</template>

<script lang="js">

import {mapGetters} from 'vuex'

export default {
  data() {
    return {
    };
  },
  props: ["actionId"],
  created(){
    this.$store.dispatch('changeReatailer', this.retailerName)
  },
  computed: {
    ...mapGetters(
      [
        'actionById',
        'retailerNameById',
        'getFromCartById',
      ]
    ),
    retailerName(){
      return this.retailerNameById(this.action.retailerId)
    },
    action(){
      return this.actionById(this.actionId)
    },
    products(){
      return this.action.products.map(product => Object.assign({cartItem:this.getFromCartById(product.id)}, product))
    },
  },
  methods: {
    addToCart(product){
      this.$store.dispatch('addToCart', product)
    }
  }
};
</script>

<style lang="scss" scoped>
@import './assets/theme.scss';

.uk-card {
  border-bottom: 1px solid $global-border;
  padding-left: 0;
  padding-right: 60px;
  .add {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: $global-primary-color;
    color: $global-background;
    svg {
      height: 0.8em;
    }
  }
}
</style>
