import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import(/* webpackChunkName: "action" */ '../views/Home.vue')
const ActionProducts = () =>
  import(/* webpackChunkName: "action" */ '../views/ActionProducts.vue')
const Feedback = () =>
  import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue')
const Cart = () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue')
const OrderPreview = () =>
  import(/* webpackChunkName: "order-preview" */ '../views/OrderPreview.vue')
const Order = () => import(/* webpackChunkName: "cart" */ '../views/Order.vue')
const Account = () =>
  import(/* webpackChunkName: "account" */ '../views/Account.vue')
const Login = () =>
  import(/* webpackChunkName: "account" */ '../views/Login.vue')
const StickyMenu = () =>
  import(/* webpackChunkName: "menu" */ '../components/StickyMenu.vue')
const StickyHeader = () =>
  import(/* webpackChunkName: "menu" */ '../components/StickyHeader.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: Home,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'Akcje' },
      menu: route => ({ active: route.name })
    },
    meta: {
      title: 'akcje'
    }
  },
  {
    path: '/action/:actionId',
    name: 'action-products',
    components: {
      default: ActionProducts,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'produkty', products: true },
      menu: { active: 'home' }
    },
    meta: {
      title: 'lista produktów'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    components: {
      default: Cart,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'Koszyk' },
      menu: route => ({ active: route.name })
    },
    meta: {
      title: 'koszyk'
    }
  },
  {
    path: '/order/:id',
    name: 'order',
    components: {
      default: Order,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'Zamówienie' },
      menu: { active: 'account' }
    },
    meta: {
      title: 'zamówienie'
    }
  },
  {
    path: '/order/preview/:id',
    name: 'orderPreview',
    components: {
      default: OrderPreview,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'Podgląd zamówienia' },
      menu: { active: 'cart' }
    },
    meta: {
      title: 'podgląd zamówienia'
    }
  },
  {
    path: '/account',
    name: 'account',
    components: {
      default: Account,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'Konto' },
      menu: { active: 'user' }
    },
    meta: {
      title: 'konto użytkownika'
    }
  },
  {
    path: '/login/:type',
    name: 'login',
    components: {
      default: Login,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: route => ({
        type: route.params.type,
        redirectUrl: route.query.redirectUrl
      }),
      header: { title: 'Konto' },
      menu: { active: 'user' }
    },
    meta: {
      title: 'Logowanie'
    }
  },
  // {
  //   path: "/action",
  //   name: "action",
  //   component: Action,
  // },
  // {
  //   path: "/account",
  //   name: "account",
  //   component: Account,
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  // },
  // {
  //   path: "/retailers",
  //   name: "retailers",
  //   component: RetailerList,
  // },
  // {
  //   path: "/actions",
  //   name: "actions",
  //   component: ActionsList,
  // },
  // {
  //   path: "/my-orders",
  //   name: "my-orders",
  //   components: {
  //     default: MyOrders,
  //     menu: StickyMenu,
  //   },
  //   props: {
  //     default: true,
  //     menu: (route) => ({ active: route.name }),
  //   },
  // },
  {
    path: '/feedback',
    name: 'feedback',
    components: {
      default: Feedback,
      menu: StickyMenu,
      header: StickyHeader
    },
    props: {
      default: true,
      header: { title: 'Kontakt z autorem' },
      menu: route => ({ active: route.name })
    },
    meta: {
      title: 'kontakt'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title =
    'Kooperatywa Lesznowola - ' + (to.meta.title || 'kupuj lokalnie!')
  next()
})

export default router
