import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { db, auth, fbProfider, googleProfider } from './firebase'
import { v4 as uuidv4 } from 'uuid'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { actions, retailers } from './actions'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentRetailer: null,
    cooperative: {
      uuid: 'f4d43370-b8f2-414a-9398-c0cff6930597',
      name: 'Kooperatywa Lesznowola',
      shortName: 'Lesznowola',
      address: 'Zamienie...'
    },
    cart: [],
    user: null
  },
  getters: {
    coopShortName: state => state.cooperative.shortName,
    getActions: () => actions,
    getCurrentRetailer: state => state.currentRetailer,
    getUser: state => state.user,
    actionById: state => id =>
      actions.filter(action => action.id == id)[0] || null,
    retailerNameById: state => id =>
      retailers.filter(retailer => retailer.id == id)[0].name || null,
    retailerNameByActionId: (state, getters) => id => {
      const action = getters.actionById(id)
      if (!action) {
        return null
      }
      return getters.retailerNameById(action.retailerId)
    },
    getCart: state => state.cart,
    getCartOrderById: state => id =>
      state.cart.filter(order => order.id == id)[0] || null,
    getFromCartById: state => id =>
      state.cart
        .map(
          order => order.products.filter(product => product.id == id)[0] || null
        )
        .filter(i => i)[0] || null,
    itemsInCart: state => state.cart.reduce((a, b) => a + b.products.length, 0),
    getPaymentDetailsForAction(state) {
      return id => {
        const action = actions.filter(action => action.id == id)[0] || null
        return action ? action.payment : null
      }
    },
    cartTotalForAction: state => id =>
      state.cart
        .filter(action => action.actionId == id)
        .reduce(
          (a, action) =>
            a +
            action.products.reduce(
              (b, product) => b + product.quantity * product.price,
              0
            ),
          0
        )
  },
  actions: {
    orderById(_, id) {
      return db
        .ref('orders/' + id)
        .once('value')
        .then(snap => snap.val())
    },
    addToCart({ commit }, product) {
      commit('addToCart', product)
    },
    removeProductFromCart({ commit }, id) {
      commit('removeProductFromCart', this.getters.getFromCartById(id))
    },
    changeReatailer({ commit }, name) {
      commit('setCurrentRetailer', name)
    },
    removeOrderFromCart({ commit }, id) {
      commit('removeOrderFromCart', id)
    },
    updateProductQuantity({ commit }, item) {
      commit('setProductQuantity', {
        product: this.getters.getFromCartById(item.productId),
        quantity: item.quantity
      })
    },
    async sendOrder({ getters }, order) {
      const dbOrder = {
        id: order.id,
        actionId: order.actionId,
        retailer: getters.retailerNameByActionId(order.actionId),
        custommer: order.custommer,
        products: order.products.filter(p => p.quantity),
        total: order.total,
        payment: order.payment || {},
        date: new Date().toLocaleString()
      }

      await db.ref('orders/' + order.id).set(dbOrder)

      axios.post(
        'https://hook.integromat.com/i198y4dggmkxef3qr1pm3ixbmjvkc8mu',
        order
      )

      return order
    },
    async signIn({ commit }, type) {
      const providers = {
        facebook: fbProfider,
        google: googleProfider
      }

      const provider = providers[type] || null

      if (!provider) {
        throw { message: 'could not find login provider' }
      }

      return await auth.signInWithRedirect(provider)
    },
    async completeSignIn({ commit }) {
      return await auth.getRedirectResult().then(function(result) {
        if (result.user) {
          commit('setUser', result.user)
          return result.user
        }
        return null
      })
    },
    signOut({ commit }) {
      auth.signOut().then(() => {
        commit('signOut')
      })
    }
  },
  mutations: {
    signOut(state) {
      state.user = null
    },
    setUser(state, user) {
      state.user = user
    },
    setProductQuantity(state, item) {
      if (item.quantity && item.product) {
        item.product.quantity = item.quantity
      }
    },
    setCurrentRetailer(state, name) {
      state.currentRetailer = name
    },
    addToCart(state, product) {
      var action =
        state.cart.filter(action => action.actionId == product.actionId)[0] ||
        null
      if (!action) {
        action = { actionId: product.actionId, products: [], id: uuidv4() }
        state.cart.push(action)
      }

      var cartItem = action.products.filter(p => p.id == product.id)[0] || null
      if (cartItem) {
        cartItem.quantity += product.quantity || 1
      } else {
        action.products.push({
          id: product.id,
          actionId: product.actionId,
          name: product.name,
          price: product.price,
          unit: product.unit,
          float: product.float || false,
          quantity: product.quantity || 1
        })
      }
    },
    RESET_STATE(state) {
      Object.assign(state, {})
    },
    removeProductFromCart(state, product) {
      if (!product) {
        return
      }

      const order =
        state.cart.filter(order => order.actionId == product.actionId)[0] ||
        null
      if (order) {
        order.products = order.products.filter(p => p.id != product.id)
      }
    },
    removeOrderFromCart(state, id) {
      state.cart = state.cart.filter(action => action.actionId != id)
    }
  },
  modules: {},
  plugins: [
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ]
})

export default store
