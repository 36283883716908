const retailers = [
  {
    id: 'f4d43370-b8f2-414a-9398-c0cff6930597',
    image: '/assets/images/retailer/pazikoland.jpg',
    name: 'Zdrowo Zapastowana'
  }
]

const actions = [
  {
    id: 'f4d43370-b8f2-414a-9398-c0cff6930597',
    date: '2021-01-27T19:00',
    retailerId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
    payment: {
      prepaid: true,
      type: 'account',
      details:
        'Paulina Wójcik <br/><b>09 1140 2004 0000 3302 7424 3110</b><br/><i>Tytuł: Zwrot za pasty DATA i nick z FB</i>'
    },
    products: [
      {
        id: 'd2eaafaa-a0dd-406f-9c71-faf7701f8712',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Zwracane słoiki od @zdrowozapastowana (212 ml)',
        price: 0,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'b31c9e44-149f-43ba-aa7e-348cc9ce315e',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Foie gras (pasztet z gęsich wątróbek) z calvadosem i ziołami SEZONOWO (212 ml)',
        price: 31,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'bfdee04b-67bd-4765-8c52-a5fea4fc6873',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Pasztetowa z pieczonych warzyw korzennych i jarmużu (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '63b276d2-c4c7-49dc-b6a3-5de885a8900a',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Paprykowa z czubrycą, syropem z granatu, orzechami włoskimi (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '0869eff8-c8a2-475d-9f7a-eca41951fb2e',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Bakłażanowa z czosnkiem niedźwiedzim i ostropestem (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '0f6cefcc-a622-4d34-9f3b-92a1d47c1ef3',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Bakłażanowa z czosnkiem niedźwiedzim, ostropestem i twarogiem (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '3861f506-384b-47b5-a1ac-daffea43b6b2',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Pieczarkowa z galgantem i olejem lnianym (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '0401674e-6e9d-4e37-9f78-9a071bc14798',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Ziołowa z anchois, kaparami i jajkiem (212 ml)',
        price: 17,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '0c3cd835-cdcb-4d47-962b-de5874050ae7',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Złoty hummus ze świeżą kurkumą i olejem sezamowym (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '2231eacc-b640-492b-9300-f1b1d3e7d11b',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Hummus ze świeżą kurkumą, pomidorami i czarnuszką (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '93b4b8e0-d815-4afe-a014-9774c8a67b98',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Selerowa z chili i octem jabłkowym (ostra) (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'dc7a66ba-843f-40d0-bb71-cc3fdd0cdbaa',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Daktylowa korzenna z nutą dzikiej pomarańczy (słodka) (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '7eee6fca-b1bd-49aa-9501-90d4db13184f',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Orzechowo-czekoladowa z daktylami i olejkiem chia (słodka) (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'befb177e-5778-425e-905c-e967945247c9',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Jarmużowa z cieciorką, hyzopem i olejem z wiesiołka (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '9efe84e0-21ad-4c73-a084-3e6d0e620e91',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Batatowa z czarnym sezamem i olejem dyniowym (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '2ea200c0-91bb-4981-b589-dcb83f0d3578',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Fasolowa z pomidorami i cząbrem (smalec) (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'c9f6d671-238b-4784-86eb-a233ca85549b',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Tzatziki z bertramem i ziołami (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '8daf1034-0249-4950-a82c-f5275f2631e2',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Ziołowe pesto z ostropestem, parmezanem i olejem z awokado (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '0919f657-6ac5-4fc2-8330-14fa761f08bd',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Ziołowe pesto z ostropestem  i olejem z awokado (bez sera) (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '3502757e-64da-487c-bf0c-dcadecf7a499',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Zielona z groszku, rukoli z limonką i olejem lnianym (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'eea19ffd-47eb-4d7a-9092-338694a4c58a',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Marchewkowa z ananasem, piri piri i olejem krokoszowym (ostra) (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '2a58b610-bfe8-4331-a91f-8efa9105b7a6',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Majonez wegański z bertramem i czarną solą (wędzoną naturalnie) (212 ml)',
        price: 9,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'e121d51a-e343-48e5-8ced-1392e93f652b',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Majonez wegański z bertramem, czarną solą i curry (212 ml)',
        price: 9,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '742f5043-8b8d-4fc8-87b5-4b80d6098c58',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Majonez wegański z bertramem, czarną solą, curry i mango (212 ml)',
        price: 9,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '883e702a-9938-4dfe-902e-dbca8eda8b4c',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Majonez wegański z bertramem, czarną solą i chrzanem (212 ml)',
        price: 9,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'd655c9da-393f-44fa-837a-fdad2aeee6af',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name:
          'Majonez wegański z bertramem, czarną solą, curry i ananasem (212 ml)',
        price: 9,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'b7bed53d-894e-49df-b6e6-f27a4b849fe4',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Ekologiczne masło roślinne z kurkumą (212 ml)',
        price: 9,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'cc5b2cf5-7eec-41ff-b493-99cf4aa67de7',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Śledzikowa z pomidorami i jalapeno (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '7a620912-21a2-4f3d-84c5-558acad581a6',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Śledzikowa z grzybami shitake i kolendrą (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: '12a0b244-5023-448a-ba43-e51893940c72',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Śledzikowa różowa z koprem włoskim i żurawiną (212 ml)',
        price: 15,
        unit: 'zł/szt',
        float: false
      },
      {
        id: 'f632e039-08bc-4c8e-9c12-943d9a08e723',
        actionId: 'f4d43370-b8f2-414a-9398-c0cff6930597',
        name: 'Ziołowa ekojarzynka z grzybami (150g)',
        price: 16,
        unit: 'zł/szt',
        float: false
      }
    ]
  }
]

export { actions, retailers }
